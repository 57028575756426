<template>
  <div
    style="max-width: 30%"
    class="sidebar-left"
  >
    <div class="sidebar">
      <div class="sidebar-content menu-cadastro-client">
        <div class="">

          <b-list-group
            class="list-group-messages"
            style="border-radius: 0 !important"
          >
            <div
              v-for="menu in menusCadastro"
              :key="menu.value + $route.path"
            >
              <b-list-group-item
                v-if="existeMenu(menu.value)"
                v-b-toggle="`${menu.value}collapse`"
                style="max-width: 200px;"
                :active="menuAtivo === menu.value"
                @click="selecionar(menu)"
              >
                <font-awesome-icon
                  v-if="menu.icon"
                  :icon="menu.icon"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ menu.text }}</span>
              </b-list-group-item>
            </div>
          </b-list-group>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.menu-cadastro-client div .sidebar-menu-list .list-group .active {
    background-color: var(--primary);
}
.menu-cadastro-client div .sidebar-menu-list .list-group  {
    .list-group-item:not(.active):not(.disabled) span {
        color: var(--primary) !important;
    }
    .list-group-item:not(.active) .badge {
        color: var(--primary) !important;
    }
}
</style>

<script>
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    pendencias: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const menusCadastro = [
      { value: 'omissaodeclaracao', text: 'Omissão de declaração', icon: '' },
      { value: 'gfipgps', text: 'Divergência GFIP x GPS', icon: '' },
      { value: 'dividaativa', text: 'Dividas ativas', icon: '' },
      { value: 'parcsnparcmei', text: 'Parcelamento (PARCSN/PARCMEI)', icon: '' },
      { value: 'debitosief', text: 'Débito (Sief)', icon: '' },
      { value: 'debitosicob', text: 'Débito (Sicob)', icon: '' },
      { value: 'parcelamentosipade', text: 'Parcelamento (Sipade)', icon: '' },
      { value: 'processofiscalsief', text: 'Processo Fiscal (Sief)', icon: '' },
      { value: 'processoarrolamentobens', text: 'Processo Arrolamento de Bens (Sief)', icon: '' },
      { value: 'parcelamentoSiefpar', text: 'Parcelamento (Siefpar)', icon: '' },
      { value: 'irregularidadecadastral', text: 'Irregularidade Cadastral', icon: '' },
    ]

    const menuAtivo = ref({})
    // const isMenuSelected = menu => menu === route.value.path.split('/')[3]

    const selecionar = menu => {
      menuAtivo.value = menu.value
      context.emit('selecionado', menu.value)
    }

    const existeMenu = menu => props.pendencias.findIndex(f => f.toLowerCase() === menu.toLowerCase()) >= 0

    selecionar(menusCadastro.find(f => f.value.toLowerCase() === props.pendencias[0].toLowerCase()))

    return {
      menuAtivo,
      selecionar,
      existeMenu,
      // Filter & Labels
      menusCadastro,
    }
  },
}
</script>

<style>

</style>
